@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;400&display=swap');
body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
}


.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 50%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(255, 254, 250);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
th, td {
  padding: 15px;
}
tr:nth-of-type(even) {
  background-color:rgba(241, 242, 208, 0.748);
}
li.selected {
  color: rgb(168, 100, 18);
  text-decoration: underline;
}
.focused {
  border-color: blue; 
  }